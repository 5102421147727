import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import ReCaptcha from '../objects/ReCaptcha';
import { ReCaptchaModalProps } from './ReCaptchaModal';

export default function ContactFormReCaptchaModal({ show, onHide, setVerified, verified }: ReCaptchaModalProps) {
    const recaptcha = new ReCaptcha('recaptcha', 'light', () => setVerified());

    useEffect(() => {
        if (show) {
            recaptcha.load();
        }
        return () => {
            recaptcha.cleanup();
        }
    }, [show])

    const handleSend = () => {
        recaptcha.reset();
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            id="contact-form-recaptcha-modal"
            dialogClassName="recaptcha-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    reCAPTCHA
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="recaptcha"></div>
            </Modal.Body>
            <Modal.Footer>
                <input form="contact-form" type="submit" onClick={handleSend} value="Senden" disabled={!verified} />
            </Modal.Footer>
        </Modal>
    );
}