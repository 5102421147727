import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

interface LoadingModalProps {
    show: boolean,
    onHide(): void,
}

export default function LoadingModal({ show, onHide }: LoadingModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            id="loading-modal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <div>
                        <Spinner animation="border" variant="success" />
                        <p>Bitte warten...</p>
                    </div>
                </Modal.Title>
            </Modal.Header>
        </Modal>
    );
}