import { useState, FormEvent, useRef } from 'react';
import emailjs from 'emailjs-com';
import TextareaAutosize from 'react-textarea-autosize';
import Swal from 'sweetalert2';
import ContactFormReCaptchaModal from '../modals/ContactFormReCaptchaModal';
import LoadingModal from '../modals/LoadingModal';

export default function ContactForm() {
    const [showReCaptchaModal, setShowReCaptchaModal] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [verified, setVerified] = useState(false);

    const form = useRef(document.createElement("form"));

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (showReCaptchaModal) {
            setShowReCaptchaModal(false);
            setShowLoadingModal(true);

            emailjs.sendForm('service_zskuhzv', 'template_wc8dhqj', form.current, 'ZXc6ielx0mDYZCcJe')
                .then((result) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'success',
                        title: 'Nachricht erfolgreich gesendet',
                        text: 'Ihre Nachricht wurde erfolgreich an uns versendet.',
                        confirmButtonColor: '#23232d',
                    });
                    const form = document.getElementById("contact-form") as HTMLFormElement;
                    form.reset();
                }, (error) => {
                    setShowLoadingModal(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Ein Fehler ist aufgetreten',
                        text: 'Ihre Nachricht konnte nicht versendet werden. Versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
                        confirmButtonColor: '#23232d',
                    });
                });
        } else {
            setShowReCaptchaModal(true);
        }
    }

    const onHide = () => {
        setShowReCaptchaModal(false);
        setVerified(false);
    }

    return (
        <div className="contact-form">
            <h2>Kontaktformular</h2>
            <p className="center">Setzen Sie sich mit uns in Verbindung. Bei Fragen stehen wir Ihnen gerne zur Verfügung.</p>
            <form ref={form} id="contact-form" onSubmit={handleSubmit}>
                <input type="text" name="name" placeholder="Name *" required />
                <input type="email" name="email" placeholder="E-Mail-Adresse *" required />
                <TextareaAutosize maxRows={17} cols={2} name="message" placeholder="Ihre Nachricht *" required />
                <input type="submit" value="Senden" />
                {
                    showReCaptchaModal &&
                        <ContactFormReCaptchaModal show={showReCaptchaModal} onHide={() => onHide()} verified={verified} setVerified={() => setVerified(true)} />
                }
                {
                    showLoadingModal &&
                        <LoadingModal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} />
                }
            </form>
        </div>
    );
}