import { useState } from 'react';
import scranioImage from '../assets/images/products/scranio.jpg';
import OrderModal from '../modals/OrderModal';

export interface Product {
    name: string,
    price: number,
    image: string,
}

export default function Products() {
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [product, setProduct] = useState<Product>({ name: "", price: 0, image: "" });

    const onClickOrder = (product: Product) => {
        setProduct(product);
        setShowOrderModal(true);
    }

    return (
        <div id="products">
            <h1>Unsere Produkte</h1>
            <h2 className="h2-lato">sCranio</h2>
            <div id="scranio" className="product image-text-inline">
                <img src={scranioImage} alt="sCranio" />
                <div className="text">
                    <p>sCranio kann durch natürliche Weise bei vielen Beschwerden helfen. Es kann unter anderem zur Behandlung gegen Schlafapnoe, Schnarchen, Reflux, Sodbrennen, Aufstossen, dauerhafter Reizhusten und Schluckstörungen eingesetzt werden.</p>
                    <p>Es kann vorkommen, dass einzelne Muskeln oder Nerven mit der Zeit geschwächt sind. Durch eine Behandlung mit dem sCranio werden auf natürliche Weise nicht nur ein, sondern bis zu 148 Muskeln gleichzeitig stimuliert, um effektiv Probleme im atem- oder Schluckprozess dauerhaft zu bekämpfen.</p>
                    <p>Die Anwendung des sCranio ist einfach, schnell und enorm effektiv. Schon nach einer kurzen Anwendungsdauer können Sie einen spürbaren Unterschied feststellen. Unser sCranio besteht aus einen körperverträglichen Silikongemisch, welches zu einer sicheren und angenehmen Behandlung führt.</p>
                    <p>Bei Fragen oder Unklarheiten stehen wir Ihnen gerne persönlich zur Verfügung. sCranio ist ein hauseigenes, schweizer Produkt der Praxis für Alternativmedizin in Olten.</p>
                    <div className="button-button">
                        <button type="button" onClick={() => onClickOrder({ name: 'sCranio', price: 149, image: scranioImage })}>Bestellen</button>
                    </div>
                </div>
            </div>
            {
                showOrderModal &&
                    <OrderModal show={showOrderModal} onHide={() => setShowOrderModal(false)} product={product} />
            }
        </div>
    );
}