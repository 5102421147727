import React from 'react';
import { useAccordionButton } from 'react-bootstrap';

interface RadioButtonShippingProps {
    children: string,
    eventKey: string,
    id: string,
    neighbours: Array<string>,
    formId: string,
    setInitPrice(): void,
    setPrice(): void,
    setShipping(): void,
}

export default function RadioButtonShipping({ children, eventKey, id, neighbours, formId, setInitPrice, setPrice , setShipping}: RadioButtonShippingProps) {
    
    const onClickRadioButton = useAccordionButton(eventKey, () => {
        const radioButton = document.getElementById(id) as HTMLInputElement;
        radioButton.checked = true;
        radioButton.required = true;
        setInitPrice();
        setPrice();
        setShipping();
        neighbours.forEach(element => {
            const radioButton = document.getElementById(element) as HTMLInputElement;
            radioButton.checked = false;
            radioButton.required = false;
        });
    });

    return (
        <div className="radio-button" onClick={onClickRadioButton}>
            <input id={id} type="radio" name={children} form={formId} value={id} required />
            <label htmlFor={children}>{children}</label>
        </div>
    );
}