import React from 'react';

export default function About() {
    return (
        <div id="about">
            <div className="youtube">
                <iframe src="https://www.youtube.com/embed/6It7b6QN5HM" className="video" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    );
}