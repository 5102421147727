import React from 'react';
import { FaUser, FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'
import shqipeZenuniImg from '../assets/images/shqipe_zenuni.jpg'
import GoogleMaps from '../components/GoogleMaps';
import ContactForm from '../components/ContactForm';

export default function Contact() {
    return (
        <div id="contact">
            <h1>Kontakt</h1>
            <div className="contact">
                <div className="round-photo">
                    <img src={shqipeZenuniImg} alt="Shqipe Zenuni" />
                </div>
                <div className="contact-info">
                    <div className="icon-text-inline">
                        <FaUser />
                        <p>Shqipe Zenuni</p>
                    </div>
                    <div className="icon-text-inline">
                        <FaPhoneAlt />
                        <a href="tel:+41786320706">+41 78 632 07 06</a>
                    </div>
                    <div className="icon-text-inline">
                        <FaMapMarkerAlt />
                        <a href="https://www.google.ch/maps/place/Dorn+%26+Craniosacral+Therapie/@47.3548101,7.9032452,17z/data=!3m1!4b1!4m5!3m4!1s0x47903171548d55ab:0xd178c7cd7c59509a!8m2!3d47.3548101!4d7.9054339?shorturl=1" target="_blank" rel="noreferrer">
                            <address>Amthausquai 33, 4600 Olten</address>
                        </a>
                    </div>
                    <div className="icon-text-inline">
                        <FaEnvelope />
                        <a href="mailto:info@dorn-craniosacral.ch">info@dorn-craniosacral.ch</a>
                    </div>
                </div>
            </div>
            <div className="form-map">
                <ContactForm />
                <GoogleMaps />
            </div>
        </div>
    );
}
