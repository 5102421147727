import React from 'react';

export default function Appointment() {

    const iFrameStyle = {
        border: '0px #ffffff none',
    };

    return (
        <div id="appointment">
            <div>
                <iframe src="https://patportal.cdm.ch/?location=a10311d9-12df-44c7-b32f-5290d960edb2&source=link&lang=de" style={iFrameStyle} height="1340px" width="100%" name="" scrolling="no" frameBorder="0" allowFullScreen></iframe>
            </div>
        </div>
    );
}