import React from 'react';

export default function TermsAndConditions() {
    return (
        <div id="terms-and-conditions">
            <h1>Datenschutz &amp; AGB</h1>
            <h3>1. Allgemeine Bestimmungen</h3>
            <div className="side-margin">
                <p>Folgende AGB regeln das Vertragsverhältnis zwischen den Patienten/Klient und der Praxis für Alternativmedizin in Olten. Mit der Buchung von Dienstleistungen, anerkennt der Patient/Klienten die Allgemeinen Geschäftsbedingungen (AGB). Abweichende AGB haben keine Gültigkeit.</p>
            </div>
            <h3>2. Behandlung</h3>
            <div className="side-margin">
                <p>Meine Praxis für Alternativmedizin in Olten erbringt Leistungen auf der Grundlage der Informationen, die vom Patienten/Klienten erteilt werden. Für die sachliche Richtigkeit und Vollständigkeit dieser Informationen ist ausschliesslich der Patienten/Klienten verantwortlich.</p>
            </div>
            <h3>3. Termine</h3>
            <div className="side-margin">
                <p>Termine können telefonisch (SMS), online, per Email oder persönlich vereinbart werden. Vereinbarte Termine sind verbindlich. Termine können mindestens 48 Stunden vor dem vereinbarten Termin persönlich, telefonisch oder per E-Mail abgesagt werden. Absagen nach dieser Frist gelten als versäumter Behandlungstermin und werden mit CHF 70 verrechnet.</p>
                <p>Verspätungen des Patienten/Klienten begründen keine Nachleistungspflicht. Bei Verspätung verkürzt sich die Behandlungszeit entsprechend.</p>
            </div>

            <h3>4. Ärztliche Verordnung</h3>
            <div className="side-margin">
                <p>Bei einer ärztlichen Verordnung wird CranioSacral Therapie nicht direkt mit der Versicherung abgerechnet. Je nach Versicherung wird der Patienten/Klienten die Franchise oder einen allfälligen Selbstbehalt von der Versicherung in Rechnung gestellt. Wenn die Versicherung gar nicht oder nur teilweise die Leistung übernimmt, muss der volle Behandlungspreis durch den Patienten entrichtet werden.</p>
                <p>Die Abklärung diesbezüglich liegt beim Patienten/Klienten selbst!</p>
            </div>
            <h3>5. Zahlungsbedingungen</h3>
            <div className="side-margin">
                <p>Die Zahlung des Rechnungsbetrages erfolgt innert 30 Tagen ab Rechnungsdatum auf das mit der Rechnung angegebene Bankkonto. Bei Nichtbezahlung der Rechnung fallen Mahnkosten von 50.- an.</p>
            </div>
            <h3>6. Datenschutz</h3>
            <div className="side-margin">
                <p>Die Praxis für Alternativmedizin in Olten schützt personenbezogene Kundendaten und behandelt diese vertraulich. Zu den personenbezogenen Daten zählen alle Daten, die zusammen mit dem Namen des Patienten/Klienten gespeichert werden. Diese Daten werden von der Praxis für Alternativmedizin in Olten in Kundendateien gespeichert und zum Zweck der Leistungserbringung und zur Abrechnung verwendet. Rechnungsstellung des Inkasso und die Buchführung notwendiger Daten an die von Ihnen beauftragten Personen und Institutionen weitererzuleiten.</p>
            </div>
        </div>
    );
}